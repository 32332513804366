import React, { useState, useRef, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import './styles/App.css'; // Import the CSS file
import axios from 'axios';



const Message = ({ text, isUser, step, handleSendMessageInside, setNewMessageInside }) => {
  useEffect(() => {
    if (step === '1') {
      const handleSelectorClick = (event, currentCount) => {
        const tagName = event.target.tagName.toLowerCase();
        const selectorValue = event.target.getAttribute('name');
        console.log('how many times?')
        handleSendMessageInside(`show me source number ${currentCount}`, 1);
        
      };

      const elements = document.querySelectorAll('[name="selector"]');
      elements.forEach((element, index) => {
        element.addEventListener('click', (event) => handleSelectorClick(event, index + 1));
        element.removeAttribute('name');
      });

    }
  },[]);

  return (
    <div className={`message ${isUser ? 'user' : 'other'}`}>
      {step === '1' ? (
        <div dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: text }} />
      )}
    </div>
  );
};


// Chat component to manage the chat window
const Chat = ({login_token, onWaitingMessage, setOnWaitingMessage, apiUrl}) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isWaitingForReply, setIsWaitingForReply] = useState(false);
  const [lastMessage, setLastMessage] = useState(undefined)
  const messagesContainerRef = useRef(null);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [ onsend, setOnsend] = useState(false);
  const fileInputRef = useRef(null);


  useEffect(() => {
    // Check if messagesContainerRef.current is not null
    if (messagesContainerRef.current) {
      // Scroll to the bottom of the messages container when messages change
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const intervalId = setInterval(fetchMessages, 10000); 
    return () => clearInterval(intervalId);
  }, [lastMessage]);

  const fetchMessages = async () => {
    console.log(lastMessage);
    await refreshMessages();
  };
  
  

  const refreshMessages = async () => { 
    if(onsend==true) {return false;}
    const response_conversations = await axios.get(`${apiUrl}/get_conversations/${login_token.toString()}/${lastMessage}`);
    console.log(response_conversations)
    const conversations = JSON.parse(response_conversations.data.data)
    console.log('timeout last message')
    console.log(response_conversations.data.last_message)
    if(response_conversations.data.last_message != 'none') {
      console.log('SET LAST MESSAGE')
      setLastMessage(response_conversations.data.last_message)
    }
    const convs = conversations.map(item => ({text: item.content, isUser: item.role === 'user', step: '0'}));
    console.log(convs);
    const msgs = messages;
    for(var i=0; i<convs.length; i++) {
      const a = convs[i].text;
      const b = convs[i].isUser
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: a, isUser: b, step: '0' }
      ]);
    }
  }

  const handleSendMessage = async (val = null, dir = 0) => {
    console.log('handle send')
    console.log(val);
    console.log(onsend)
    if(onsend==true) {return false;}
    try {
      console.log(newMessage)
        if(dir ==0 && newMessage == '') {
          return false;
       }
       //setOnsend(true);
       console.log(newMessage)
       setOnsend(true)
       if (val!=null) {
        console.log('here val');
        console.log(val);
        setMessages([...messages, { text: val, isUser: true, step: '0' }]);
        //setNewMessage(val)
       } else {
        console.log('no val')
        setMessages([...messages, { text: newMessage, isUser: true, step: '0' }]);
       }
        setIsWaitingForReply(true);
        // Make a POST request to http://localhost:5000/message
        const payload = {
          text: (val!=null) ? val : newMessage,
          token: login_token.toString()
        }
        //'89das9dkasjdasd2dksa'
        //console.log(payload)
        const response = await axios.post(`${apiUrl}/send_chat`, payload);
        console.log(response.data)
        const data = response.data.data
        setMessages((prevMessages) => [
          ...prevMessages,
          {text: data, isUser:false, step: response.data.step}
        ]);
        setOnsend(false)
        setIsWaitingForReply(false);
        setNewMessage('');
        if(response.data.last_message != 'none') {
          setLastMessage(response.data.last_message)
        }
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    } catch (error) {
      console.error('Error sending message:', error.toString());
    }
  };


  const uploadSource = async (selectedFiles) => {
    try {
    const formData = new FormData();
    if (selectedFiles) {
    Array.from(selectedFiles).forEach((file, index) => {
      formData.append(`file${index}`, file);
      console.log(index);
      console.log(file);
    });
    formData.append('payload', JSON.stringify({ "token": login_token.toString() }));
    console.log(formData);
    const response = await axios.post(`${apiUrl}/upload`, formData);
    console.log(response)
    
    const response_conversations = await axios.get(`${apiUrl}/get_conversations/${login_token.toString()}/${lastMessage}`);
    console.log(response_conversations)
    const conversations = JSON.parse(response_conversations.data.data)
    if(response_conversations.data.last_message != 'none') {
      setLastMessage(response_conversations.data.last_message)
    }
    console.log(response.data.last_message)
    console.log(conversations)
    console.log(conversations.length);
    const convs = conversations.map(item => ({text: item.content, isUser: item.role === 'user', step: '0'}));
    console.log(convs);
    const msgs = messages;
    for(var i=0; i<convs.length; i++) {
      const a = convs[i].text;
      const b = convs[i].isUser
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: a, isUser: b, step: '0' }
      ]);
    }
    
    return 1;
    } else {
      console.error('No files selected');
      // Handle the case where no files are selected 
      return 0;
    }
    } catch(error) {
      return 0;
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && onsend == false && newMessage!='') {
      setOnsend(true)
      handleSendMessage();
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    // You can perform any additional handling here if needed
    // ...

    // Call the uploadSource function
    uploadSource(selectedFiles);
  };

  const handleUploadButtonClick = () => {
    // Trigger the file input click
    fileInputRef.current.click();
  };

  return (
    <div className="chat-window">
      <div className="messages-container" ref={messagesContainerRef}>
        {messages.map((message, index) => (
          <Message key={index} text={message.text} isUser={message.isUser} step={message.step} setNewMessageInside={setNewMessage} handleSendMessageInside={handleSendMessage}/>
        ))}
        {isWaitingForReply && <div className="other">Waiting for reply...</div>}
      </div>
      <div className="input-container">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button onClick={e => handleSendMessage()}>Send</button>
        <button onClick={handleUploadButtonClick}>Upload</button>
        {/* Hidden file input */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

// Login component to handle user login
const Login = ({ onLogin, apiUrl }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleKeyPressLogin = (e) => {
    if (e.key === 'Enter') {
      handleLogin ();
    }
  };

  const handleLogin = async () => {
      const payload = {
        username: username,
        password: password
      }
   

    const response = await axios.post(`${apiUrl}/signin`, payload);
    console.log(response.data)
    if (response.data != '0') {
      onLogin(response.data);
    } else {
      alert('Wrong credentials!')
    }
  };

  return (
    <div className="login-container">
      <label>
        <input
          className="inputLogin"
          placeholder="Username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyPress={handleKeyPressLogin}
        />
      </label>
      <label>
        <input
          placeholder="Password"
          className="inputLogin"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPressLogin}
        />
      </label>
      <button onClick={handleLogin}>Signin</button>
    </div>
  );
};


const App = () => {
  const [token, setToken] = useState(undefined);
  const [onWaitingMessage, setOnWaitingMessage] = useState(false)
  const [apiUrl, setApiUrl] = useState('https://dailyswipe.rankit.it')
  //const [apiUrl, setApiUrl] = useState('http://127.0.0.1:5000')
  //http://127.0.0.1:5000 //https://dailyswipe.rankit.it

  const handleLogin = (newToken) => {
    setToken(newToken);
  };

  return (
    <div className="app">
      {token ? (
        <Chat login_token={token} onWaitingMessage={onWaitingMessage} apiUrl={apiUrl}/>
      ) : (
        <Login onLogin={handleLogin} apiUrl={apiUrl} />
      )}
    </div>
  );
};

export default App;
